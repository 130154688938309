import React, { Component } from "react";

import brandImg1 from "../assets/images/brand/zemo_brand.png";
import brandImg2 from "../assets/images/brand/sp_brand.jpeg";
import brandImg3 from "../assets/images/brand/licenciamento_brand.jpeg";
import brandImg4 from "../assets/images/brand/olivas_brand.png";
import brandImg5 from "../assets/images/brand/roca_brand.jpeg";

class BrandTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <ul className="brand-style-2">
          <li>
            <img src={brandImg1} alt="Sanrlei Polini Engenharia Consultiva" />
          </li>
          <li>
            <img src={brandImg2} alt="Zemo Bank" />
          </li>
          <li>
            <img src={brandImg3} alt="Licenciamento Brasil" />
          </li>
          <li>
            <img src={brandImg5} alt="Olivas Digital" />
          </li>
          <li>
            <img src={brandImg4} alt="Outlet Roca" />
          </li>
        </ul>
      </React.Fragment>
    );
  }
}
export default BrandTwo;
