import React, { Component } from 'react';
import Header from "../component/header/HeaderFour";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/Footer";

 class error404 extends Component {
    
    render() {
        return (
            <>
                {/* Start Page Error  */}
                <div className="error-page-inner bg_color--4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <h1 className="title theme-gradient">404!</h1>
                                    <h3 className="sub-title">Página não encontrada</h3>
                                    <span>
                                        Desculpe, mas não encontramos a página solicitada. Se encontrou algum problema, 
                                        contate a gente: <a 
                                            style={{color: "#fbb03c"}}
                                            href='mailto:contato@hypercodetech.com.br'>contato@hypercodetech.com.br</a> :) 
                                    </span>
                                    <div className="error-button">
                                        <a className="btn-default" href="/">Voltar para página inicial</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Error  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
            </>
        )
    }
}
export default error404;
