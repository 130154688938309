import React, { Component } from "react";
import { FiActivity, FiCloud, FiCommand, FiCreditCard, FiMonitor } from "react-icons/fi";


const ServiceList = [
    {
        icon: <FiCreditCard />,
        title: 'Payments & Cards',
        description: 'Facilitamos transações seguras e eficientes para o seu negócio. Integração simplificada com plataformas de pagamento.'
    },
    {
        icon: <FiActivity />,
        title: 'Monitoria',
        description: 'Monitoria inteligente: otimize seu desempenho com insights em tempo real.'
    },
    {
        icon: <FiCloud />,
        title: 'Foco em Infra Cloud',
        description: 'Transforme seu negócio com soluções escaláveis e eficientes na nuvem.'
    },
]
class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row row--25">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    {val.icon}
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;