import React, { Component } from "react";
import { Link } from "react-router-dom";
import logoHyper from "../../assets/images/logo/logo_hyper.png";
import useAnalyticsEventTracker from "../AnalyticsEventTracker";

const logoUrl = <img src={logoHyper} alt="Hypercode Tech" />;
const gaEventTracker = useAnalyticsEventTracker('Footer');


class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer-area footer-style-01 bg_color--6">
          {/* Start Call to Action Area  */}
          <div className="im-call-to-action-area ptb--70 im-separator">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-8 col-xl-6 col-md-12 col-sm-12 col-12">
                  <div className="inner">
                    <h2 className="text-white mb--0">
                      Temos uma solução personalizada para seu negócio! Vamos conversar?
                    </h2>
                  </div>
                </div>
                <div className="col-lg-4 col-xl-4 offset-xl-2 col-md-12 col-sm-12 col-12">
                  <div className="call-to-cation-tbn text-left text-lg-right mt_md--20 mt_sm--20">
                    <a
                      className="btn-default btn-large btn-border btn-opacity"
                      href="https://calendly.com/edu-hyper"
                      target="_blank"
                      onClick={()=>gaEventTracker('contact_email')}
                    >
                      Agendar Via Calendly
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Call to Action Area  */}

          {/* Start Footer Area  */}
          <div className="footer-wrapper ptb--70">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div className="ft-text">
                    <div className="logo">
                      <Link to="/">{logoUrl}</Link>
                    </div>
                    <p>
                      Copyright © 2024 <Link to="/">HyperCode</Link>.
                      Todos os direitos reservados. Desenvolvido com carinho pela equipe da Hypercode.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Footer Area  */}
        </footer>
      </React.Fragment>
    );
  }
}
export default Footer;
